function show_alert(text, type, time, scroll) {
    var scroll = scroll || true;
    var text = text || '';
    var type = type || 'info';
    $('.alert-message').fadeOut(500, function () {
        setTimeout(function () {
            $('.alert-message .text-info').html(text);
            $('.alert-message').attr('class', 'alert-message ' + type).fadeIn(500).css('display', 'table');
    //        if (scroll) {
    //            $('html, body').stop().animate({
    //                scrollTop: 0
    //            }, 1000);
    //        }
        }, 100);
        if (time) {
            setTimeout(function () {
                fade_alert();
            }, time);
        }
    });

}
function fade_alert() {
    $('.alert-message').fadeOut('fast', function () {
        $('.alert-message .text-info').html('');
        $('.alert-message').attr('class', 'alert-message');
    });
}