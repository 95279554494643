var playerLoaded    = false;
var playerId        = 'iframe[id^="cnb-player"]';
var playerClicked   = false;
var version = '16';
var b = browserDetectNav();
var url = window.Config.BASE_PATH + '/api/v1/auth/get-user-key';
var userKey;
autoPlay = false;

var utmAutoPlay = location.search;

if (utmAutoPlay.indexOf("autoPlay=true") >= 0) {
    autoPlay = 'true';
}

vPlay = function() {
    if(playerLoaded){
        playViewer();
    }
};

playViewer = function() {
    // safari
    // if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
    //     return false;
    // }

    if(playerClicked) {
        var ifr = document.querySelector(playerId);
        if(ifr){
            ifr.contentWindow.postMessage('play', "*");
            setTimeout(function () {
                ifr.contentWindow.focus();
            }, 500);
        }
    }
};

window.addEventListener("message", function (e) {
    if (e.data == 'web-viewer-ready-text' || e.data == 'web-viewer-ready') {
        playerLoaded = true;
        setTimeout(function () {
            playViewer();
        }, 500);
    }
});

function initViewer(){
    $.get(url, function (tokenData) {
        userKey = tokenData.data.userKey;

        if($('.min-height').hasClass('book-page')){
            $('.web-container').css({
                'height': windowHeight - 50,
                'padding-bottom': '0'
            });
        }

        if (autoPlay) {
            if($('.min-height').hasClass('book-page')){
                $('body').addClass('v-played');
                $('.logo').addClass('anim');
                $('.video-preview, .book-video').height(windowHeight);
            }
            autoPlay = false;
        }

            var options = {
                placeholder: '.web-viewer',
                width: '100%',
                height: '100%',
                userKey: userKey,
                autoPlay: true,
                version: version,
                apiKey: 'Ww74JOBa5N-aSMN8X2zVb-bXLdoauM3d-HwN2fi1asf',
                iframe: !!$('.web-viewer').data('iframe'),
                bookGUID: guid
            };

            window.wid = new CNPlayerWidget();
            window.wid.init(options);
    });
}

// if (autoPlay == 'true') {
//     initViewer();
// }

if (window.guid) {
    window.addEventListener('load', function () {
        // for IE ONLY
        if (isIe() ) {
            setTimeout(function () {
                window.CNPlayerWidget.loadBook(guid);
            }, 1000);
        }
    });
}

window.addEventListener("message", function (e) {
    if (e.data == 'web-viewer-ready-text' || e.data == 'web-viewer-ready') {
        if (typeof $('iframe[id^="cnb-player"]')[0] !== 'undefined') {
            setTimeout(function () {
                $('iframe[id^="cnb-player"]')[0].contentWindow.focus();
            }, 1000);
        }
        /*
            /*if (!isIe()) {
            $('iframe[id^="cnb-player"]').focus();
            }*/
    }
});

$(document).ready(function(){

    $(document).on("click", '.viewerPlay', function (e) {
        guid = $(this).data('guid') ? $(this).data('guid') : guid;

        if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
            playerClicked = false;
        } else {
            playerClicked = true;
        }

        // playerClicked = true;
        e.preventDefault();

        var bookTitle = $(this).data('title');

        // if (typeof ga === 'function') {
        //     ga('send', 'event', 'video', 'play', bookTitle);
        // } else {
        //     //Not loaded
        //     setTimeout(ga('send', 'event', 'video', 'play', bookTitle),500);
        // }
        if (typeof window['ga'] == 'function'){
            ga('send', 'event', 'video', 'play', bookTitle);
        }
        

        initViewer();

        vPlay();

        if ($(this).closest('.min-height').hasClass('book-page')) {
            $("html, body").animate({ scrollTop: 0 }, 600);
            $('body').addClass('v-played');
            $('.logo').addClass('anim');
        } else {
            $('.viewer-pos').show();
            history.pushState(null, null, location.href);
        }
    });

        function closeViewerModal(event) {
            $('.viewer-pos').hide();
            $('.web-viewer').html('');
            if (event.type === 'click') {
                history.back()
            }
        }

        // window.onpopstate = function (event) {
        //     var modalContainer = document.querySelector('.viewer-pos');
        //     var isViewerModalVisible = window.getComputedStyle(modalContainer).display === 'block';
        //     if (isViewerModalVisible) {
        //         closeViewerModal(event);
        //     }
        // };

        $('.web-container .hide-viewer').on('click', closeViewerModal);
        $(document).keyup(function (e) {
            if (e.key === "Escape" && $(".hide-viewer").is(":visible")) {
                closeViewerModal(e);
            }
        });
});

// Polyfill for CustomEvent for IE
(function () {

    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    window.CustomEvent = CustomEvent;
})();