var ShoppingCart = (function(_,Events){
    var Cart = function(container, options){
        var container = container;
        container.on('click',function(e){
            e.preventDefault();
            location.href = window.Config.BASE_PATH + '/store/checkout';
        });
        var defaults = {
            url : window.Config.BASE_PATH + "/store/cart"
        };
        var options = _.extend((options||{}), defaults);
        var url = options.url;

        this.getCartContainer = function(){
            return container;
        }

        this.getUrl = function(){
            return url;
        }
        _.extend(this,Events);
        this.on('cart.add', this.render);
        this.on('cart.remove', this.render);
        this.on('cart.clear', this.render);
    }



    Cart.prototype.add = function(data, callback){
        var url = this.getUrl();
        var me = this;
        $.ajax({
            url : url + '/add',
            type : 'post',
            dataType : 'json',
            data : {product : data},
            success : function(resp){
                me.trigger('cart.add', data, resp);                
                if(typeof callback == 'function'){
                    callback.call();
                }
                if (typeof window.callbackForCart === 'function') {
                    window.callbackForCart();
                }
            }
        })
    }

    Cart.prototype.remove = function(token){
        var url = this.getUrl();
        var me = this;
        $.ajax({
            url : url + '/remove',
            type : 'get',
            dataType : 'json',
            data : {token : token},
            success : function(resp){
                me.trigger('cart.remove', token, resp);
            }
        })

    }

    Cart.prototype.clear = function(){
        var url = this.getUrl();
        var me = this;
        $.ajax({
            url : url + '/clear',
            type : 'post',
            dataType : 'json',
            success : function(resp){
                me.trigger('cart.clear', resp);
            }
        })
    }

    Cart.prototype.render = function(callback){

        var me = this;
        var $cartContainer = me.getCartContainer();
        var url = me.getUrl();
        var $box = $('.book-buttons-box'); //  по непонятной причине класс-обертка на карточках товаров в стор и на блоках товаров в корзине
        var $itemTotalFree = $('.total-free');
        var $itemTotalPrice = $('.total-price');

        var buttons = '.book-buttons'; // класс, по непонятной причине навешенный на ценник, кнопки "купить" и "добавить в корзину"в карточке товара ... и  корзизне на блоке блок discount и тексте 'It's already yours'
        var buttonsToUser = '.book-buttons-to-user, js-text-allready-yours'; // класс навешенный на текст 'It's already yours' в карточке товара и в корзине?
        var buttonsToCart = '.book-buttons-to-cart'; // класс навешенный на кропку "товар в корзине" в карточке товара и !!! блок discount в корзине
        var buttonsToBuy = '.book-buttons-to-buy'; // класс, по непонятной причине навешенный на ценник, кнопки "купить" и "добавить в корзину"
        $.ajax({
            url : url + '/render',
            type : 'post',
            dataType : 'json',
            success : function(resp) {
                if (resp.country.isAllow === false) {
                    // если пользователь из неправильной страны...отображаем соответствующий текс с формой
                    $('.side-menu, .mobile .side-menu-wrapper, .tablet .side-menu-wrapper').hide();
                    $('.for-not-allow-c').parent().addClass('for-not-allow');
                    $('.for-not-allow-c').fadeIn();
                    return;
                }
                if (resp.country.code === 'XX') {
                    //analytics
                }

                if (resp.success) {
                    // переписываем число товаров в ярлычке корзинки в шапке
                    $cartContainer.find('.count_items').text(resp.count);

                    // переписываем число товаров во всплывающем модальном окне ".books-buy"
                    if(resp.count == 1){
                        $('.books-buy').find('.count_items').text(resp.count + ' Item');
                    } else {
                        $('.books-buy').find('.count_items').text(resp.count + ' Items');
                    }

                    // скрываем или отображаем ярлычек корзины с добавленными товарами в шапке
                    if(resp.count == 0){
                        $cartContainer.hide();
                    }else{
                        $cartContainer.fadeIn();
                    }

                    // Скрываем все кнопки добавляющие товар в корзину, ценник на странице стор ??? удаляя класс show ???
                    // и блок discount и текст 'It's already yours' на странице корзины
                    $(buttons).removeClass('show');
                    $('.book-store__price, .book-store__controls').addClass('v-hidden');

                    $box.each(function (index, itemContanier) { // проход по всем оберткам карточек товаров / товарам добавленным в корзину
                        var $itemContanier = $(itemContanier);
                        var id = $itemContanier.data('id');
                        var token = $itemContanier.data('item');


                        // change price // подсчет  цены и скидок ??? в корзине ???
                        if (token in resp.data) {
                            var item = resp.data[token];
                            $itemContanier.find('.price-with-discount').html(item['price_with_discount']);
                            var discount = '';
                            if (item['discount'] && !isNaN(item['discount_full'])) {

                                if (item['discount_type']=='percent') {
                                    discount += '<span><span class="discout-percent">'+item['discount']+'</span>%</span>';
                                }

                                // if(resp.promo.product_id === id){
                                if(item.discount_reason === 'PROMO'){
                                    discount += '<br/>'+
                                            '<span class="price-save promo-save">You save:<span class="saved">'+
                                            '$<span class="discount-you-save">'+
                                            parseFloat(item['discount_full']).toFixed(2)+
                                            '</span></span></span><span class="substrate-discount promo-used show">Promo Code</span>';

                                } else {
                                    discount += '<br/>'+
                                            '<span class="price-save">You save:<span class="saved">'+
                                            '$<span class="discount-you-save">'+
                                            parseFloat(item['discount_full']).toFixed(2)+
                                            '</span></span></span>';
                                }

                            }else{
                                discount += '<span class="discount-box">--</span>';
                            }

                            $itemContanier.find('.discount-box').html(discount);
                        }

                        // does user have // если пользователь уже обладает таким товаром ?
                        if (resp.books.indexOf(id) !== -1) {
                            $(buttonsToCart).addClass('hidden');
                            $itemContanier.find(buttonsToUser).addClass('show');
                            $itemContanier.find($itemTotalPrice).hide();
                            $itemContanier.find($itemTotalFree).addClass('show');
                            $itemContanier.addClass('is-user');
                            return true;
                        }

                        // если такой товар уже доавлен в корзину отображаем ссылку "in the cart"
                        if (resp.booksInCart.indexOf(id) !== -1) {
                            // console.log(resp.booksInCart.indexOf(id));
                            $itemContanier.find(buttonsToCart).addClass('show');
                            $itemContanier.find(buttonsToCart).closest('.cb-price__body').addClass('bg-transparent');
                            $itemContanier.find('.book-store__in-the-cart').removeClass('v-hidden');

                            return true;
                        }

                        $itemContanier.find(buttonsToBuy).addClass('show');                        
                        $itemContanier.find('.book-store__price, .book-store__controls').removeClass('v-hidden');
                    });


                    $cartContainer.find('.total').text('$'+resp.total);
                    $('.books-buy').find('.subtotal').text('$'+resp.total);

                    me.trigger('cart.render', resp);
                }

                if(typeof callback == "function"){
                    var res = callback.call(this, resp.success, resp);
                }


            },
            error: function(xhr, textStatus, errorThrown) {
                $(buttons).removeClass('show');
                $(buttonsToBuy).addClass('show');
            }
        })
    }

    Cart.prototype.getTotalSum = function(){
        var url = this.getUrl();
        var me = this;
        $.ajax({
            url : url + '/get-total-sum',
            type : 'get',
            dataType : 'json',
            success : function(resp){
                me.trigger('cart.get-total-sum', resp.total);
            }
        })
    }

    Cart.prototype.getTotalItems = function(callback){
        var url = this.getUrl();
        var me = this;
        $.ajax({
            url : url + '/render', // get-total-items',
            type : 'get',
            dataType : 'json',
            success : function(resp){
                me.trigger('cart.get-total-sum', resp.total);
                if(typeof callback === 'function'){
                    callback.call(me, resp.count, resp);
                }
            }
        })
    }

        Cart.prototype.buyAndCheckout = function(element, l){
            var guid = $(element).data('id');
            var name = $(element).data('name');
            this.add({
                id : guid,
                product : name,
                qty : 1
            }, function(){
                location.href = '/store/checkout' + '#step-3';
                l.stop();
            });
    }
        Cart.prototype.goToStep3 = function(element, l){
            l.stop();
            this.render(function(){
                $('.js-login-full-box').slideUp();
                $('.login-done-box').slideDown();
                goToStep(3);
            });
    }

    var Modal = function(div, ok, close, params){
        var container = div;
        var options = params;
        var callbacks = {
            'success' : ok || null,
            'close' : close || null
        };
        var me = this;
        $(container).find('.close-btn').on('click', function(e){
            e.preventDefault();
            me.hide();
            if(typeof callbacks.close == 'function'){
                callbacks.close.call(me);
            }
        })

        $(container).find('.ok-btn').on('click', function(e){
            e.preventDefault();
            me.hide();
            if(typeof callbacks.success == 'function'){
                callbacks.success.call(me);
            }
        })
        this.show = function(){
//            $('.overlay').show();
//            $(container).find('.count_items').text(options.count);
//            $(container).find('.subtotal').text(options.total);
            $(container).fadeIn();
        }
        this.hide = function(){
            $(container).fadeOut();
//            $('.overlay').hide();
        }
    };

    Cart.Modal = Modal;

    return Cart;
})(_,Backbone.Events);