function timer(block, speed, pr_count) {
    var elem = $("#" + block).html();
    var milliSec = 0;

    var timer = setInterval(function () {
        milliSec = milliSec + 1000;
        $("#" + block).html(milliSec / 1000);
        if (milliSec / 1000 == pr_count) {
            clearInterval(timer);
        }
    }, speed);
}

function currentTime() {
    var currentTime = 1000;
    if ($(window).width() < 1400) {
        currentTime = 600;
    } else {
        currentTime = 1000;
    }
    return currentTime;
}