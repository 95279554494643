$(document).ready(function () {
    function bookPreview() {
        $('.s-preview').each(function () {
            if ($(window).width() < 744 && $(window).width() > 540) {
                var dataH = $(this).attr('data-horizontal');
                $(this).attr('src', dataH);
            } else {
                var dataV = $(this).attr('data-vertical');
                $(this).attr('src', dataV);
            }
        });
    }

    bookPreview();

    cart = new ShoppingCart($('.cart'));


    // $(document).on('click','.store-buttons .buy, .js-btn-buy-now', function(e){
    //     e.preventDefault();
    //     var $this = $(this);
    //     // var l = Ladda.create(this);
    //     // l.start();
    //     var guid = $this.data('id');
    //     var name = $this.data('name');
    //     var type = $this.attr('data-type');
    //     cart.add({
    //         id : guid,
    //         product : name,
    //         qty : 1
    //     }, function(){
    //         MA_ClickBuy(e, type, name);
    //         // скрываем ценник и кнопки добавления в корзину
    //         $this.parents('.flip-container').find('.book-buttons-to-buy').removeClass('show');
    //         $this.closest('.store-book__cnt').find('.book-store__controls, .book-store__price').addClass('v-hidden');
    //         // показываем кнопку-ссылку "уже в корзине"
    //         $this.parents('.flip-container').find('.in-the-cart').addClass('show');
    //         $this.closest('.store-book__cnt').find('.book-store__in-the-cart').removeClass('v-hidden');

    //         // проверка - авторизован ли пользователь
    //         Auth.hasIdentity(function(res, response){
    //             if (res) {
    //                 // шлем авторизованного пользлвателя в корзину на третий шаг
    //                 location.href = Auth.options.url.checkoutStep3;
    //                 setTimeout(function(){
    //                 //    l.stop();
    //                 }, 3000);
    //             } else {
    //                 // не авторизованному пользователю отображаем форму логина/авторизации
    //                 $('.js-modal--reg-login').addClass('modal--show');
    //                 $this.parents('.flip-container').addClass('show-back');
    // //                $(me).parents('.flip-container').find('.store-info').fadeOut('300');
    //                 $this.parents('.flip-container').find('.store-info').addClass('no-visible');
    //                 $this.parents('.flip-container').find('.back').fadeIn('600');
    //                 // l.stop();
    //             }
    //         });
    //     });

    // });

    $(document).on('click', '.store-close', function () {
        $(this).parents('.store-book').find('.store-buttons .cart-btn').hide();
        $(this).parents('.store-book').find('.store-buttons .old-price').hide();
        $(this).parents('.store-book').find('.store-buttons .in-the-cart').fadeIn();
    });

    $('.percent-done').each(function (i) {
        $(this).find('.animated-line').animate({ width: parseInt($(this).find('.percent-value').html(), 10) + "%" }, 1000);
    });

    $(document).on('click', '.continue-shopping', function () {
        $('.c-popup').fadeOut();
        $('.flip-container').removeClass('show-back');
        $('.flip-container').find('.back').fadeOut('300');
        $('.flip-container').find('.store-info').fadeIn('600');
        $('.store-info').removeClass('no-visible');
    });
    //            cart.render();

    $('.add-to-cart, .js-btn-add-to-cart').on('click', function (e) {
        e.preventDefault();
        var btn = this;
        var l = Ladda.create(this);
        l.start();
        var guid = $(this).data('id');
        var fullName = $(this).data('name');
        var type = $(this).attr('data-type');

        var bookId = $(this).data('bookid');
        var bookTitle = $(this).data('booktitle');
        var bookChannels = $(this).data('bookchannels') || null;
        var bookType = $(this).data('booktype') || null;
        var bookPrice = $(this).data('bookprice') || 0.01;

        if ($('.min-height').hasClass('try-now')) {
            if (typeof window['ga'] == 'function') {
                ga('send', 'event', 'button', 'push', 'BUY-NOW');
            }
        } else {
            MA_ClickBuy(e, type, fullName);
        }
        cart.add({
            id: guid,
            product: fullName,
            qty: 1
        }, function () {
            l.stop();

            $(window).trigger('cart.add', [bookId, bookTitle, bookChannels, bookPrice, 'USD']);

            if ($('.min-height').hasClass('book-page')) {
                // analytics e-commerce for add-to-cart
                window.dataLayer.push({
                    'dimension9': 'Product',
                    'ecommerce': {
                        'currencyCode': 'USD', // валюта товара
                        'add': {
                            'actionField': { 'list': 'Product Card' }, // статический параметр
                            'products': [
                                {
                                    'name': bookTitle,  // вывести название товара http://prntscr.com/lj2l9j
                                    'id': bookId, // id товара (уникальный код для каждого товара)
                                    'price': bookPrice, // цена товара http://prntscr.com/lj2mlc
                                    // 'brand': bookBrand, // бренд товара http://prntscr.com/lj2nm3	
                                    'category': bookChannels, // категория товара http://prntscr.com/lj2n9f
                                    'variant': bookType, // тип товара http://prntscr.com/lj2o2t
                                    'quantity': '1',
                                    'position': '0' // 
                                },
                            ]
                        }
                    },
                    'event': 'gtm-ee-event',
                    'gtm-ee-event-category': 'Enhanced Ecommerce',
                    'gtm-ee-event-action': 'Adding a Product to Cart',
                    'gtm-ee-event-non-interaction': false,
                });
                // end analytics e-commerce for add-to-cart
            }

            var modal = new ShoppingCart.Modal(
                '.books-buy',
                function () {
                    location.href = '/store/checkout';
                }, null, null);
            modal.show();
        })
    });

    $(window).on('resize', function () {
        bookPreview();
    });
});
