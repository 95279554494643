function openYoutube(youtube_video){

    $('#screen').show();
    $("div.logo").addClass("anim");
    $('#screen .c-popup').show();

    if(youtube_video){
        $("#player_no_video").hide();
        $("#player").show();

        var src = "https://www.youtube.com/embed/"+youtube_video+"?rel=0&vq=hd720&border=0&autohide=1&fs=1&enablejsapi=1&autoplay=1&cc_load_policy=1&playsinline=0";//";
        var div = document.getElementById("player");

        div.getElementsByTagName("iframe")[0].src = src;
        div.getElementsByTagName("iframe")[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

    } else {
        $("#player_no_video").show();
        $("#player").hide();
    }
}

function closeYoutube(){
    $("div.logo").removeClass("anim");

    if($("#player:visible")){
        document.getElementById("player").getElementsByTagName("iframe")[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    $('#player').hide();
    $('#player_no_video').hide();
    $('#screen').fadeOut();
    
    if($('.min-height').hasClass('try-now')){
        $('.book-page.min-height').height('auto');
    }
}