/* used on homepage and /about page */

function playNextVideo() {
    setTimeout(function () {
        var stopVideoId = $('#da-slider .da-slide-current').prev().find('video').attr("id");
        var stopVideo = document.getElementById(stopVideoId);
        if (stopVideo != null) {
            stopVideo.pause();
        }
        var videoId = $('#da-slider .da-slide-current video').attr("id");
        var video = document.getElementById(videoId);
        if (stopVideo != null) {
            video.play();
        }
    }, 500);
}

function playPrevVideo() {
    setTimeout(function () {
        var stopVideoId = $('#da-slider .da-slide-current').next().find('video').attr("id");
        var stopVideo = document.getElementById(stopVideoId);
        if (stopVideo != null) {
            stopVideo.pause();
        }
        var videoId = $('#da-slider .da-slide-current video').attr("id");
        var video = document.getElementById(videoId);
        if (stopVideo != null) {
            video.play();
        }
    }, 500);
}

function playFirstVideo() {
    var videoId = $('#da-slider .da-slide-current video').attr("id");
    var video = document.getElementById(videoId);
    if (videoId != null && !$('html').hasClass('portrait')) {
        video.play();
    }
}

function daSliderInitialize() {
    if (!$('html').hasClass('tablet portrait')) {
        $('#da-slider').cslider({
            autoplay: true,
            interval: 7000,
            bgincrement: 0
        });
    }
}