window.dataLayer = window.dataLayer || [];

var instance = new TypeIt('#typeitjs', {
    strings: 'prove their talent... become famous... be heard... and make money!',
    speed: 75,
    loop: true
});

function windowInnerHeight(){
    var height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
    document.getElementById('da-slider').style.height = height+'px';
}

function setEqualHeight(columns, maxHeight){
    var tallestcolumn = 0;
    columns.each(function(){
        $(this).height('auto');
        currentHeight = $(this).height();
        if(currentHeight > maxHeight){
            $(this).addClass('gray-hid');
        }
        if(currentHeight > tallestcolumn){
            tallestcolumn = currentHeight;
        }
    });
    if (tallestcolumn > maxHeight) {
        columns.height(maxHeight);
    } else {
        columns.height(tallestcolumn);
    }    
}

var flag = true;
var player;

function onPlayerReady(event) {
    event.target.playVideo();
}

function onPlayerStateChange(event) {
    if (event.data === 1) {
        // $(window).trigger('video.play', {title: event.target.getVideoData().title});
    }
}

function showVideo(id) {
    $('#youtube-play').css("visibility", "visible").addClass('show').animate({ opacity: 1 }, 1000);

    if (!window.YT) {
        initYoutubeAPI();
        window.onYouTubeIframeAPIReady = function () {
            initYoutubePlayer(id);
        }
    } else {
        player.loadVideoById(id, 0)
        player.playVideo()
    }
}

function closeYoutubeFull() {
    // $(window).trigger('video.stop', { player: player });
    $('#youtube-play').removeClass('show').animate({ opacity: 0 }, 500, function () {
        $('#youtube-play').css("visibility", "hidden"); //.find('.video-screen').css({"visibility": "hidden"});
        player.stopVideo();
    });
}

function initYoutubeAPI() {
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function initYoutubePlayer(id) {
    player = new window.YT.Player('ytplayer', {
        playerVars: { 'controls': 1 },
        videoId: id,
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange,
        },
    });
}

$(document).keyup(function (e) {
    if ($('#youtube-play').hasClass('show')) {
        if (e.keyCode == 32)
            player.pauseVideo(); // probel
    }
    if (e.keyCode == 27)
        closeYoutubeFull(); // esc
});


$(document).ready(function () {
//    var menuHeight = $('header').height();

    var htmlHasClassTablet = $('html').hasClass('tablet');
    var htmlHasClassMobile = $('html').hasClass('mobile');
    if ($(window).width() >= 1024 && !htmlHasClassTablet && !htmlHasClassMobile) {
        windowInnerHeight();
    }

    $('body').addClass('move-buttons');
    var re = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?/ig;
    var img_url = $('.layer0').css('backgroundImage');
    img_url = img_url.match(re);
    if (img_url) {
        $('<img />').attr('src', img_url).load(function () {
            $('.video-layer').addClass('show');
        });
    } else {
        $('.video-layer').addClass('show');
    }
    
    function homeCollectionWidth(){
        setTimeout(function () {
            $('.slides').width($('body').width());
            $('#slider .item').width($('body').width());
        }, 100);
    }

    homeCollectionWidth();

    $( window ).resize(function() {
        homeCollectionWidth();
    });

    // function initClassicCollectionSlider(){
        $('#carousel-2').on('init', function (event, slick, currentSlide, nextSlide) {
            $( window ).on( "load", function() {
                setFullImgAfterLoad($('#slider'));
            });
        }).slick({
            arrows: true,
            slidesToShow: 5,
            dots: false,
            centerPadding: 0,
            centerMode: true,
            infinite: true,
            focusOnSelect: true,
            speed: 400,
            asNavFor: '.slides',
            //                respondTo: 'slide',
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 981,
                    settings: {
                        arrows: true,
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 745,
                    settings: {
                        arrows: true,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 541,
                    settings: {
                        arrows: true,
                        slidesToShow: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {            
            $('#slider').attr('data-background', $('#slider .slick-slide').eq(nextSlide).attr('data-id'));
            setFullImgAfterLoad($('#slider'));
        });
    
        $('.slides').on('init', function () {
            $('#slider .item').addClass('show');
        }).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            dots: false,
            centerPadding: 0,
            asNavFor: '#carousel-2',
            adaptiveHeight: true,
            respondTo: 'slide'
        });
    // }
    

    $('.carousel').slick({
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        dots: false,
        speed: 400,
        infinite: false,
        lazyLoad: 'ondemand',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    arrows: false,
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 541,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    function featuresSliderInit(){    
        if ($(window).width() > 991) {

            $('.features_nav').slick({
                arrows: false,
                slidesToShow: 3,
                dots: false,
                centerPadding: 0,
                centerMode: true,
                infinite: true,
                focusOnSelect: true,
                asNavFor: '.features-slider',
                //                respondTo: 'slide',
                // adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 981,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 745,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 541,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            }).on('afterChange', function(event, slick, currentSlide, nextSlide){
                var inFocus = $('.features_nav .slick-current').attr('data-slick-index');
                $('.features-slider .slick-current').removeClass('slick-current');
                $('.features-slider .slick-slide[data-slick-index="' + inFocus + '"]').addClass('slick-current');
            });
            $('.features-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                // fade: true,
                dots: false,
                centerPadding: 0,
                asNavFor: '.features_nav',
                // adaptiveHeight: true,
                respondTo: 'slide'
            }).on('afterChange', function(event, slick, currentSlide, nextSlide){
                var inFocus = $('.features-slider .slick-current').attr('data-slick-index');
                $('.features_nav .slick-current').removeClass('slick-current');
                $('.features_nav .slick-slide[data-slick-index="' + inFocus + '"]').addClass('slick-current');
            });

        }
    }

    featuresSliderInit();

    $(window).on('resize', function () {
        featuresSliderInit();
    });

});

function setFullImgAfterLoad(element) {    
    if ($(window).width() > 981) {   
            var re = /(\/[a-z\_\-0-9]{1,})(\/[a-z\_\-0-9]{1,})(\/[a-z\_\-0-9]{1,})(\/[a-z\_\-0-9]{1,})\.(jpg|png|jpeg|webp)/ig;
            var img_url = $(element).css('backgroundImage', '').css('backgroundImage');
            img_url = img_url.match(re)[0];
            var timestamp = Date.now();
            $(element).attr('data-time', timestamp);
            if (img_url) {
                img_url = img_url.replace("-min.", ".");
                $('<img />').attr('src', img_url).load(function () {
                    if ($(element).attr('data-time') == timestamp) {
                        $(element).css('backgroundImage', 'url(' + img_url + ')');
                    }
                });
            }
            
    }
}

$(document).ready(function () {
    // fix label if input not empty
    // function labelFix() {
    //     $('.cb-form-control').each(function () {
    //         $(this).on('keyup click', function () {
    //             var val = $(this).val();
    //             if (val != null && val.length > 0) {
    //                 $(this).parent().find('label').addClass('not-empty');
    //             } else {
    //                 $(this).parent().find('label').removeClass('not-empty');
    //             }

    //         });
    //     });
    // }

    // labelFix();

    $(window).scroll(function () {

        if ($('html').hasClass('tablet')) {
            $('.curtains').addClass('move-curtains');
            $('.show-f').addClass('z-animation');
        } else {
            if ($('.share-chapter').length >= 1 && $('.share-chapter').offset().top <= $(window).scrollTop() + 500) {
                $('.curtains').addClass('move-curtains');
                $('.show-f').addClass('z-animation');
            } else {
                $('.curtains').removeClass('move-curtains');
                $('.show-f').removeClass('z-animation');
            }
        }
    });

});

// e-commerce
window.dataLayer.push({
'dimension9': 'Premium subscribe', 
 'ecommerce': {
   'currencyCode': 'USD', // валюта канала
   'impressions': [
    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': 'Get Premium subscribe – Home page' 
    },
    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': 'What do I get – Home page' 
    },
    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': 'Premium Cine-Books – Home page' 
    },
    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': 'Premium Cine-Stories – Home page' 
    },
    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': 'Premium Classic Audiobooks – Home page' 
    },

    {
      'name': 'Premium subscribe', 
      'id': '1', // id товара из вашей CRM
      'price': '0.01',
      'position': '1', 
      'list': '$14.99 per month after month FREE – Home page' 
    },
]
 },
 'event': 'gtm-ee-event',
 'gtm-ee-event-category': 'Enhanced Ecommerce Premium subscribe', 
 'gtm-ee-event-action': 'List Impressions – Home page',
 'gtm-ee-event-non-interaction': true
});