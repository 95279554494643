$(document).ready(function () {
  $('.scroll-down-to-viewer').click(function () {
    //header height
    const yOffset = -55;
    const element = document.getElementById("viewer-block-scroll");
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  });
});
