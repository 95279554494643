// open viewer subscribe popup
document.addEventListener("buy_or_subscribe_popup_open", function (e) {
  $.get("/api/v1/shop/get-book-button?book_id=" + e.detail.bookId, function (res) {
    console.log('eeeee', e)
    var data = res.data;
    console.log(data);
    var popup = document.getElementById("buy_or_subscribe_popup");
    setPopupStyling(popup);
    // if (data.is_private_channel) {
    //   preparePrivateChannelPopup(popup, e.detail.slug);
    //   return;
    // }
    preparePlatformChannelPopup(popup, e.detail, data);
  });
});

$(document).ready(function () {
  $("#scroll-to-book-block").click(() => {
    const bookLink = $("#scroll-to-book-block").attr("data-book-link");
    $(".c-popup").fadeOut();
    if (document.getElementById("main-book-block-button")) scrollToMainBookBlock();
    else window.location.href = bookLink + "#main-book-block-button";
  });
});

function scrollToMainBookBlock() {
  //header height
  // const yOffset = 55;
  const element = document.getElementById("main-book-block-button");
  // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  element.scrollIntoView({ behavior: "smooth" });
}


function setPopupStyling(popup) {
  popup.style.display = "block";
  popup.style.zIndex = 10000001;
}

function preparePrivateChannelPopup(popup, slug) {
  popup.querySelector(".success-credits-to-use").style.display = "none";
  popup.querySelector(".c-popup-window").style.width = "400px";
  popup.querySelector("#scroll-to-book-block")
    .setAttribute("data-book-link", window.location.origin + "/store/" + slug);
}

function preparePlatformChannelPopup(popup, detail, data) {
  popup.querySelector(".no-credits-to-use").style.display = "none";
  popup.querySelector(".success-card__tmb a").setAttribute("href", detail.link_to_web);
  popup.querySelector(".success-card__type svg use").setAttributeNS("http://www.w3.org/1999/xlink",
    "xlink:href", "#icon-" + detail.type.replace(" ", "-").replace("_", "-"));
  // popup.querySelector(".trial-users-action a").setAttribute("href", data["url"]);
  // prepareSubscribeButton(data, popup, detail);
  setPopupImage(popup, detail);

  popup.getElementsByClassName("book-title-p")[0].innerHTML = detail.title;
  popup.getElementsByClassName("book-author-p")[0].innerHTML = detail.author;
  prepareBuyButton(popup, detail);
  setPrice(detail, popup);
}

function prepareSubscribeButton(data, popup, detail) {
  if (data["in_cart"]) {
    popup.querySelector(".book-buttons-to-buy").style.display = "none";
    popup.querySelector(".book-buttons-to-cart").style.display = "block";
  }
  if (!data["text"]) {
    popup.querySelector(".trial-users-action").style.display = "block";
    var subscribeLink = popup.querySelector(".trial-users-action a");
    subscribeLink.setAttribute("href", subscribeLink.getAttribute("href") + "?b=" + detail.bookId);
  } else {
    popup.querySelector(".trial-users-action").style.display = "none";
    popup.querySelector(".cb-price__body").style.display = "block";
    popup.querySelector(".trial-info").innerHTML = "$14.99 per month. Cancel anytime.";

    if (!data["disabled"]) {
      popup.querySelector(".cb-price__body .cb-price__btn_w a").classList.remove("cb-btn--disabled");
      popup.querySelector(".cb-price__body .cb-price__btn_w a").classList.add("cb-btn--danger");
      popup.querySelector(".cb-price__body .cb-price__btn_w a").setAttribute("onclick", "");

    }
    popup.querySelector(".cb-price__body .cb-price__btn_w a").innerHTML = data["text"];
    popup.querySelector(".cb-price__body .cb-price__btn_w a").setAttribute("href", data["url"]);

  }
}

function setPopupImage(popup, detail) {
  var img = popup.getElementsByClassName("success-card__image")[0];
  img.setAttribute("src", "https://cine-books.com" + detail.cover);
  img.setAttribute("title", detail.title);
  img.setAttribute("alt", detail.title);
}

function prepareBuyButton(popup, detail) {
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-style", "zoom-out");
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-type", "order");
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-id", detail.GUID);
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-name", detail.title);
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-booktitle", detail.title);
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-bookid", detail.bookId);
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-booktype", detail.type);
  popup.getElementsByClassName("add-to-cart")[0].setAttribute("data-bookprice", detail.webPrice);
}

function setPrice(detail, popup) {
  console.log('detail', detail)

  if (detail.webDiscountValue && detail.webDiscountType) {
    popup.querySelector(".old-price").innerHTML = "$" + detail.webPrice.toFixed(2);
    popup.querySelector(".new-price").innerHTML = "$" + getNewPrice(detail).toFixed(2);
  } else {
    popup.querySelector(".book-price").innerHTML = "$" + detail.webPrice.toFixed(2);
  }
}

function getNewPrice(detail) {
  switch (detail.webDiscountType) {
    case "float":
      return detail.webPrice - detail.webDiscountValue;
    case "percent":
      return detail.webPrice - (detail.webPrice * (detail.webDiscountValue / 100));
    default:
      return detail.webPrice;
  }
}


