/* JS FOR ALL PAGES */

var windowHeight = $(window).height();
var menuHeight = $('header').height();
var body = document.body, timer;

function dispatchLoggedInEvent(userKey) {
    console.log('dispatchLoggedInEvent ' + userKey);
    var loginEvent = new CustomEvent('set_logged_in', { 'detail': {userKey: userKey} });
    document.dispatchEvent(loginEvent);
}

$(document).ready(function () {
    /* i dont know what is it */
    $(window).on('load', function () {
        $('body').addClass('move-buttons');
    });

    /* start loader on the button */
    $('.ladda-link').click(function (e) {
        var l = Ladda.create(this);
        l.start();
    });

    /* fading new modals by click on overlay */
    $(document).on('click', '.modal-close, .modal-overlay', function () {
        $(this).closest('.modal').removeClass('modal--show');
    });

    //Функционирование всплывающего окна при клике
    $(document).on('click', '.c-close, .c-popup-window .yc-return', function () {
        $('.desktop .c-popup').fadeOut();
        $('.tablet .c-popup, .mobile .c-popup').hide();
        $('html, body').css('overflow','visible');
    });

    //функционал сообщений alert`ов
    $(document).on('click', '.close-alert', function () {
        $(this).parents('.alert-message').fadeOut();
    });

    // open contact window
    $('.go-contact').click(function (e) {
        e.preventDefault();
        $('#contact').fadeIn();
        $('#contact').attr('data-subject', $(this).attr('data-subject'));

    });   

    // отправка форм контакт
    $('.send-contact').click(function (e) {
        e.preventDefault();
        var sendBtn = $(this);
        var name = this.form.name.value;
        var email = this.form.email.value;
        var text = this.form.text.value;
        var subject = this.form.subject.value;
        var type = this.form.type.value;

        if( !name ){
            System.showErrors(locale['TEXT_NAME_CANNOT_BE_EMPTY']);
            return;
        }

        if( !email ){
            System.showErrors(locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
            return;
        }

        if( !subject ){
            System.showErrors(locale['SUBJECT_CANNOT_BE_EMPTY']);
            return;
        }

        if( !text ){
            System.showErrors(locale['TEXT_MESSAGE_CANNOT_BE_EMPTY']);
            return;
        }

        var l = Ladda.create(this);
        l.start();

        Auth.sendContact(email, text, name, subject, type, function (res, msg) {
            l.stop();
            $('.loading').hide();
            if (res) {
                show_alert(msg, 'success', null);
                $('.desktop .c-popup').fadeOut();
                $('.tablet .c-popup, .mobile .c-popup').hide();
                sendBtn.parents('form').find('[type="reset"]').trigger('click');
            } else {
                show_alert(msg, 'error', null);
            }
        });
    });

    $('.send-contact-faq').click(function (e) {
        e.preventDefault();
        var sendBtn = $(this);
        var form = $(this).parents('form');
        var l = Ladda.create(this);
        l.start();
        var email = form.find('input[name=email]').val() || null;
        var name = form.find('input[name=name]').val() || null;
        var text = form.find('textarea[name=text]').val() || null;
        var subject = form.find('input[name=subject]').val() || null;
        var type = form.find('input[name=type]').val() || null;

        Auth.sendContact(email, text, name, subject, type, function (res, msg) {
            l.stop();
            if (res) {
                show_alert(msg, 'success', null);
                $('.desktop .c-popup').fadeOut();
                $('.tablet .c-popup, .mobile .c-popup').hide();
                sendBtn.parents('form').find('[type="reset"]').trigger('click');
            } else {
                show_alert(msg, 'error', null);
            }
        });
    });

    // аналитика для даунлод линкс 
    $(document).on('click', '.download-app-link', function (e) {
        var me = this;
        var appPlatform = $(this).attr('data-appPlatform');
        var href = $(me).attr('href');
        MA_DownloadAppLink(href, appPlatform);
    });

    $(document).on('click', '.close-video-galery', function () {
        $(this).parent().fadeOut(1000);
        $(".video-js")[0].player.pause();
    });

    // $(document).on('mouseenter', '.play-icon', function () {
    //     $(this).prev().addClass('hide');
    // });
    // $(document).on('mouseleave', '.play-icon', function () {
    //     $(this).prev().removeClass('hide');
    // });        
     
});
function isIe() {
    return /MSIE \d|Trident.*rv:/.test(navigator.userAgent);
}

$(document).ready(function () {
    /* render shopping cart */
    if (typeof window['ShoppingCart'] == 'function'){
        cart = new ShoppingCart($('.cart'));
        cart.render();
    }

    var body = document.body,
            timer;

    //Comments

    $(document).on('click', '.wrtite-comment .reply, .wrtite-comment .no-reply', function () {
        $(this).toggleClass('open');
    });

    $(document).on('click', '.wrtite-comment .comments-show', function () {
        $(this).parents('.com-block').next('.second-level').delay(100).slideToggle();
    });   

    $(document).on('click', '.comment-button', function(e){
        Auth.hasIdentity(function(status, resp){
            if(status === false){
                e.preventDefault();
//                    System.warning(resp.message);
//                    $('.join-window').fadeIn();
                initiateRegistration('b2c');
                Auth.showRegisterForm($('#join-window'));
//                    $('html, body').css('overflow','hidden');
                return false;
            }
        });
    });
    

    $('#resend_email, .send-confirm-email').on('click', function (e) {
        e.preventDefault();
        var id = $(this).data('id');
        Auth.sendConfirm(id, function (res, msg, fullResponse) {
            show_alert(msg, fullResponse.status);
        });
    });

    /* главное меню в header, открытие подменю при клике на username */
    $(document).on('click', '.menu .user', function () {
        $('.user-auth-box .user-menu').toggleClass('show-border');
        $('.user-auth-box .user-menu-wrapper').slideToggle('slow');
        $(this).toggleClass('show-user-menu');
    });

    $('html').click(function (event) {
        if ($(event.target).closest(".menu nav>ul>li.menu-garmoshka").length === 0) {
            $('.user-auth-box .user-menu').removeClass('show-border');
            $('.user-auth-box .user-menu-wrapper').slideUp('slow');
            $('.menu .user').removeClass('show-user-menu');
        }
    });

    /* credits popup start */
    var creditsBtn = $('.user-credits__toggle');
    $(creditsBtn).each(function(){
        $(this).on('click', function(){
            $(this).next().toggleClass('show');
        });
    });

    $(document).click( function(event){
        if( $(event.target).closest(".user-credits__header").length ) 
          return;
        $(".user-credits__info-block").removeClass('show');
        event.stopPropagation();
    });

    /* credits popup end */

    // burger munu hiding
    var burgerMenu = $('.m-burger-menu');
    if(burgerMenu) {
        $(document).on('mouseup touchend', function (e) {                
            if (!burgerMenu.is(e.target)
                    && burgerMenu.has(e.target).length === 0 
                    && $('.js-toggle-mobile-menu').has(e.target).length === 0) {
                $('.site-header').removeClass('mobile-menu-is-open');
            }
        });
    }       

    $(window).scroll(function () {
        if ($(window).scrollTop() == 0) {
//            $('.side-menu').fadeOut();
        }
        if ($(window).scrollTop() > 0) {
            if(!$('body').hasClass('layout-b2b-cabinet')){
                $('.logo').addClass('anim');
                $('.side-menu').fadeIn().css('display', 'table');
            }
        } else {
            if(!$('body').hasClass('v-played')){
                $('.logo').removeClass('anim');
            }

            if($('body').hasClass('layout-b2b-cabinet')){
                $('.logo').addClass('anim');
            }
        }
    });

});

$(function(){
    $('.css-label').on('click', function(e){
        e.preventDefault();
        $(this).prev('input[type=checkbox],input[type=radio]').click();
    })
    $('.clickable').on('click', function(e){
        e.preventDefault();
        var href = $(this).data('href');
        window.location = href;
    });
    Auth.initProfileDropdown();
    $('.form-control').focus(function(){
        if($(this).prev('.input-group-addon').length > 0){
            $(this).prev('.input-group-addon').addClass('hover');
        }
    })
    $('.form-control').blur(function(){
        if($(this).prev('.input-group-addon').length > 0){
            $(this).prev('.input-group-addon').removeClass('hover');
        }
    });

    /* Покупка книги - всплывающее окно ??? */
    // $(document).on('click', '.buy.new', function () {
    //     $('.books-buy').fadeIn();
    //     $('.cart-link').fadeIn();
    //     return false;
    // });
})