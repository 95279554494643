var mdash = String.fromCharCode(8212);

// first symbol to uppercase
function upperCaseFirstSymbol(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
}

// e-commerce

function ecommerceFunction(logicBlock, action, page){    
    var eventAction, position;
    page = upperCaseFirstSymbol(page);

    if(page == 'Channel'){
        position = 0; 
    } else {
        position = 1; 
    }

    var pushData = {
        'actionField': {'list': logicBlock}, 
        'products': [
            {
            'name': 'Premium subscribe', 
            'id': '1', // id товара из вашей CRM
            'price': '0.01',
            'position': position, 
            'quantity': '1'
            },
        ]
    };

    if(action === 'add'){//8212
        eventAction = 'Adding a Channel to Cart ' + mdash + ' ' + page + ' page';

        window.dataLayer.push({
            'dimension9': 'Premium subscribe',
             'ecommerce': {
               'currencyCode': 'USD', // валюта товара
               'add': pushData
             },
             'event': 'gtm-ee-event',
             'gtm-ee-event-category': 'Enhanced Ecommerce Premium subscribe',
             'gtm-ee-event-action': eventAction,
             'gtm-ee-event-non-interaction': true,
        });
    } else {
        eventAction = 'Channel Clicks ' + mdash + ' ' + page + ' page';

        window.dataLayer.push({
            'dimension9': 'Premium subscribe',
             'ecommerce': {
               'currencyCode': 'USD', // валюта товара
               'click': pushData
             },
             'event': 'gtm-ee-event',
             'gtm-ee-event-category': 'Enhanced Ecommerce Premium subscribe',
             'gtm-ee-event-action': eventAction,
             'gtm-ee-event-non-interaction': true,
        });
    }
}