/* NOTE: worked with tooltipler, jQuery and Auth.simpleCheckEmail function */
function isValidEmailAddress(emailAddress) {
    if(!emailAddress ||  emailAddress.length<0){
        return false;
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)){2,100}(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}
function isValidPassword(password) {
    var pattern = new RegExp(/^.{6,30}$/i);
    return pattern.test(password);
}
function isValidUrl(url) {
    // var pattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?(\/|\/([\w#!:.?+=&%!\-\/]))?$/);
    var pattern = new RegExp(/(^http|^https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/);
    // var pattern = new RegExp(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/);
    return pattern.test(url);
}
function validateEmail(input){
    var email = $(input).val();
    // if empty email show nothing
    if(!email ||  email.length<0){
        $(input).removeClass('error').removeClass('success');
        destroyTooltipler(input);
        return true;
    }
    if(isValidEmailAddress(email)){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    }else{
        $(input).removeClass('success').addClass('error');
        addTooltipler(input, TEXT_EMAIL_IS_INCORRECT);
        return false;
    }
}
function validateUrl(input){
    var link = $(input).val();
    if(!link ||  link.length<0){
        $(input).removeClass('error').removeClass('success');
        destroyTooltipler(input);
        return true;
    }
    if(isValidUrl(link)){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    }else{
        $(input).removeClass('success').addClass('error');
        return false;
    }
}
function validateServerEmail(input){
    var email = $(input).val();
    if(!email ||  email.length<1){
        $(input).removeClass('success').addClass('error');
        return false;
    }

    var noIssetEmail = Auth.simpleCheckEmail(email);
    if($(input).attr('id')!='join-username' && !$(input).data('isset')) {
        if(noIssetEmail){
            $(input).removeClass('success').addClass('error');
            return false;
        }else{
            $(input).removeClass('error').addClass('success');
            return true;
        }
    }else{
        if(noIssetEmail){
            $(input).removeClass('error').addClass('success');
            return true;
        }else{
            $(input).removeClass('success').addClass('error');
            return false;
        }
    }
}
function validatePassword(input){
    var password = $(input).val();
    // console.log(password);
    if(!password ||  password.length<1){
        $(input).removeClass('success').removeClass('error');
        return false;
    }
    if(isValidPassword(password)){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    } else if(password.length > 30) {
        $(input).removeClass('success').addClass('error');
        addTooltipler(input, TEXT_PASSWORDS_MAX_SYMBOLS);
        return false;
    }else{
        $(input).removeClass('success').addClass('error');
        addTooltipler(input, TEXT_PASSWORDS_MIN_SYMBOLS);
        return false;
    }
}

function validateTextfield(input){
    var len = $(input).data('max');
    if(len == undefined) {
        len = 255;
    }
    var textfield = $(input).val();
    if(!textfield || textfield.length<0){
        $(input).removeClass('error').removeClass('success');
        destroyTooltipler(input);
        return true;
    }

    if(
        (textfield.length>=3 && textfield.length<=len) ||
        $(input).hasClass('anketa_percentnonrealistic') ||
        $(input).hasClass('js-surname') ||
        ($(input).hasClass('anketa_volume') && textfield.length>=1) ||
        ($(input).hasClass('should-filled') && textfield.length>3)
    ){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    }else{
        $(input).removeClass('success').addClass('error');
        return false;
    }
}
function validateMessagefield(input){
    var message = $(input).val();
    if(!message ||  message.length<0){
        $(input).removeClass('error').removeClass('success');
        destroyTooltipler(input);
        return true;
    }

    if(message.length>=6 && !$(input).hasClass('authors-textarea') && !$(input).hasClass('aboutAuthor') && !$(input).hasClass('shortDescription')
            || ($(input).hasClass('aboutAuthor') && message.length>=6 && message.length<=10000)
            || ($(input).hasClass('shortDescription') && message.length>=6 && message.length<=600)){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    } else if(($(input).hasClass('authors-textarea') && message.length>255)
            || ($(input).hasClass('aboutAuthor') && message.length>10000)
            || ($(input).hasClass('shortDescription') && message.length>600)){
        $(input).removeClass('success').addClass('error');
        return true;
    }else if($(input).hasClass('should-filled') && message.length>6){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
    }else{
        $(input).removeClass('success').addClass('error');
        return false;
    }
}

function validateMessageB2Bfield(input){
    var message = $(input).val();
    if(!message ||  message.length<0){
        $(input).removeClass('error').removeClass('success');
        destroyTooltipler(input);
        return true;
    }
    if(message.length>=6 && message.length<=255){
        $(input).removeClass('error').addClass('success');
        destroyTooltipler(input);
        return true;
    }else{
        $(input).removeClass('success').addClass('error');
        return false;
    }
}

$(document).ready(function () {
    $('.email-input, .password-input, #contact-form input[type="text"], #contact-form textarea, .form-control, .cb-form-control').on('focus', function(e) {
        $(this).addClass('focus');
        destroyTooltipler(this);
        $(this).removeClass('success').removeClass('error');
    });
    $('.email-input').on('change blur input', function(e) {
        if(!$(this).hasClass('focus')){
            return false;
        }
        validateEmail($(this));

        if($(this).hasClass('success')){
            $(this).next().attr('title', '');
        } else if($(this).hasClass('error')){
            addTooltipler($(this), TEXT_EMAIL_IS_INCORRECT);
        } else {
            destroyTooltipler($(this));
        }
    });

    $('.password-input').on('change blur input', function(e) {
        if(!$(this).hasClass('focus')){
            return false;
        }
        validatePassword($(this));
    });

    $('.form-control[type="url"]').on('change blur input', function(e) {
        if(!$(this).hasClass('focus')){
            return false;
        }
    });

    var pass;
    $('input[type="password"]').keyup(function(){
        $('.alert-message').hide();
        pass = $(this).val();
        if(pass.length > 30){
            System.showErrors(TEXT_PASSWORDS_MAX_SYMBOLS);
        }
    });

    $(document).on('change blur input keydown', '.comment-textarea', function(e) {
        if($(this).val().length > 1000 || $(this).val().length < 6){
            $(this).removeClass('success').addClass('error');        
        } else {
            $(this).removeClass('error').addClass('success');
        }
    });
});