function addTooltipler(input, message){
    var indicator = $(input).siblings('.indicator');
    if (typeof $.fn.tooltipster === 'function' && !$(indicator).hasClass("tooltipstered")) {
        $(indicator).tooltipster({
            animation: 'fade',
//            delay: 200,
            position: 'top-right',
            offsetX: 13,
            maxWidth: 200,
            content: $('<span>'+message+'</span>')
        });
    }
}
function destroyTooltipler(input){
    var indicator = $(input).parents('.input-block').find('.indicator');
    if (typeof $.fn.tooltipster === 'function' && $(indicator).hasClass("tooltipstered")) {
        $(indicator).tooltipster('destroy');
        $(indicator).attr('title', '');
    }
}